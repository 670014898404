<template>
  <div
    style="display: flex; background: #143693; min-height: 60px; padding: 0px; border-radius: 10px; text-align: center;"
    :class="$vuetify.breakpoint.xs && 'mb-14'"
  >
    <v-col
      class="mx-0"
      align-self="center"
      cols="12"
    >
      <a
        href="https://clubedoprodutor.esteiogestao.com.br/academia-do-leite/"
        target="_blank"
      >
        <span
          style="color:white; font-size: 2.0em; font-style: italic; align-self: center;"
          class="mb-1"
        >
          Visite a <b>Academia do Leite</b> e fique por dentro das novidades do setor.
          <span style="color: yellow;"><b> acesse agora ></b></span>
        </span>
      </a>
    </v-col>
  </div>
</template>

<script>
  export default {
    setup () {

    },
  }
</script>
